import angular from "angular";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import join from "lodash/join";
import concat from "lodash/concat";
import immutable from "seamless-immutable";

import { fetchDossierComments } from "@skryv/core-ng1/core/store/actions/dossiercomments";
import {
  selectDossierDetails,
  selectMilestonesForDossier,
} from "@skryv/core-ng1/core/store/selectors/dossiers";
import { fetchEnabledTasksForDossier } from "@skryv/core-ng1/core/store/actions/cases";
import { selectPinnedDossierComments } from "@skryv/core-ng1/core/store/selectors/dossiercomments";
import { getFieldFromDocument } from "@skryv/core-ng1/core/util/dossierHelpers";
import {
  fetchDossierDetails,
  fetchDossierMilestones,
} from "@skryv/core-ng1/core/store/actions/dossiers";

import { calculatePayment } from "./helpers";

import template from "./FbuDossier.html";
import "./FbuDossier.scss";

const namespace = "fl/components/dossier/FbuDossier";

const FBU_OVEREENKOMST_DOCUMENT_KEY = "fbu_overeenkomst";
const BETALINGSREGELS_DOCUMENT_KEY = "betalingsregels";

angular.module(namespace, []).component("fbuDossier", {
  template,
  bindings: {
    apiDossier: "<",
  },
  controller: function ($ngRedux, $state, $timeout) {
    "ngInject";
    const $ctrl = this;
    const disconnect = $ngRedux.connect(mapStateToThis, {
      fetchDossierComments,
      fetchDossierMilestones,
      fetchDossierDetails,
      fetchEnabledTasksForDossier,
    })(this);
    this.$onDestroy = disconnect;
    this.$onInit = () => {
      this.processStepsOpen = false;
      this.dossierId = this.apiDossier.id;
      this.fetchDossierDetails(this.dossierId);
      this.fetchDossierComments(this.dossierId);
      this.fetchDossierMilestones(this.dossierId);

      // two seconds after initialisation, we fetch everything a second time again; this to be sure that we have the most recent version
      $timeout(this.refreshDossier, 2000);
    };

    this.refreshDossier = () => {
      // since we do not use the apiDossier currently, we do not refetch it
      this.fetchDossierDetails(this.dossierId);
      this.fetchDossierMilestones(this.dossierId);
      this.fetchDossierComments(this.dossierId);
      this.fetchEnabledTasksForDossier(this.dossierId);
    };

    // important milestones
    this.goToHistoryPage = () => {
      $state.go(
        "dossierHistory",
        { dossierId: this.dossierId },
        { reload: false }
      );
    };

    function formDocumentData(dossierDetails) {
      return concat(
        createOvereenkomstOverview(dossierDetails),
        createPaymentsOverview(dossierDetails),
        createGenericDossierOverview(dossierDetails)
      );
    }

    function createPaymentsOverview(dossierDetails) {
      if (!dossierDetails) return [];

      return [
        {
          label: "1ste tegemoetkoming",
          value: calculatePayment(
            BETALINGSREGELS_DOCUMENT_KEY,
            dossierDetails,
            0
          ),
        },
        {
          label: "2e tegemoetkoming",
          value: calculatePayment(
            BETALINGSREGELS_DOCUMENT_KEY,
            dossierDetails,
            1
          ),
        },
      ];
    }

    function createGenericDossierOverview(dossierDetails) {
      if (!dossierDetails) return [];

      return [{ label: "Dossier ID", value: dossierDetails.dossier.id }];
    }

    function createOvereenkomstOverview(dossierDetails) {
      if (!dossierDetails) return [];

      const ocmwNaam = getFieldFromDocument(
        FBU_OVEREENKOMST_DOCUMENT_KEY,
        dossierDetails,
        ["ocmw", "naam_ocmw"]
      );
      const huurders = getFieldFromDocument(
        FBU_OVEREENKOMST_DOCUMENT_KEY,
        dossierDetails,
        "huurders_lijst"
      );
      const address = addressPretty(
        getFieldFromDocument(
          FBU_OVEREENKOMST_DOCUMENT_KEY,
          dossierDetails,
          "adres_huurwoning"
        )
      );

      return [
        { label: "OCMW", value: ocmwNaam },
        { label: "Adres", value: address },
        { label: "Huurder(s)", value: huurders },
        { label: "Verhuurder(s)", value: getVerhuurdersString(dossierDetails) },
      ];
    }

    function getVerhuurdersString(dossierDetails) {
      const typeVerhuurder = get(
        getFieldFromDocument(
          FBU_OVEREENKOMST_DOCUMENT_KEY,
          dossierDetails,
          "type_verhuurder"
        ),
        ["selectedOption"]
      );

      switch (typeVerhuurder) {
        case "natuurlijk_persoon": {
          const verhuurdersField = get(
            getFieldFromDocument(
              FBU_OVEREENKOMST_DOCUMENT_KEY,
              dossierDetails,
              "verhuurders"
            ),
            ["elements"]
          );
          const verhuurders = map(
            verhuurdersField,
            (verhuurder) => `${verhuurder.voornaam} ${verhuurder.naam}`
          );
          return join(verhuurders, ", ");
        }
        case "rechtspersoon": {
          return get(
            getFieldFromDocument(
              FBU_OVEREENKOMST_DOCUMENT_KEY,
              dossierDetails,
              "verhuurder_is_rechtspersoon"
            ),
            ["naam_onderneming"]
          );
        }
        default: {
          return "Unkown type verhuurder!";
        }
      }
    }

    function mapStateToThis(state) {
      const processInfo = map(
        filter(orderBy(get($ctrl.dossierDetails, "task"), ["ended"]), {
          active: false,
        }),
        (task) => {
          return {
            id: task.id,
            name: task.name,
            task: task,
            status: "finished",
            action: (task_) => $state.go("task", { taskId: task_.id }),
            hasAction: true,
            canExecute: true,
            actionLabel: "Open",
          };
        }
      );

      const pinnedComments = immutable.asMutable(
        selectPinnedDossierComments(state, $ctrl.dossierId),
        { deep: true }
      );

      // TODO: which milestones matter?
      const importantMilestones = [
        "ingediend_via_eloket",
        "manueel_opgestart",
        "dossier_afgewezen",
        "bijkomende_stukken_opgevraagd",
        "termijn_reactie_expertise_verlopen",
        "reactie_expertise_ontvangen",
        "geen_reactie_expertise_ontvangen",
        "besluit_opgestuurd",
        "betaling_uitgevoerd",
        "expertiseverslag_opgestuurd",
        "reactie_besluit_ontvangen",
        "geen_reactie_besluit_ontvangen",
        "termijn_reactie_besluit_verlopen",
      ];

      const allMilestones = selectMilestonesForDossier(state, $ctrl.dossierId);
      const milestonesToShow = filter(allMilestones, (milestone) =>
        importantMilestones.includes(milestone.key)
      );

      const dossierDetails = selectDossierDetails(state, $ctrl.dossierId);
      const documentData = formDocumentData(dossierDetails);

      const activeTasks = filter(
        orderBy(get(dossierDetails, "task"), ["created"]),
        { active: true }
      );

      return {
        milestonesToShow,
        dossierDetails,
        activeTasks,
        processInfo,
        pinnedComments,
        documentData,
      };
    }

    const addressPretty = (address) => {
      if (!address || !get(address, "municipality")) return;
      const { street, housenumber, boxnumber, zipcode, municipality } = address;
      const formattedBoxnumber =
        boxnumber !== undefined ? `, bus ${boxnumber}` : "";
      return `${street} ${housenumber}${formattedBoxnumber}\n${zipcode} ${municipality}`;
    };
  },
});

export { template };
export default namespace;
