import isUndefined from "lodash/isUndefined";

import { getFieldFromDocument } from "@skryv/core-ng1/core/util/dossierHelpers";

export function calculatePayment(documentKey, dossierDetails, indexOfPayment) {
  const paidPremium = getFieldFromDocument(
    documentKey,
    dossierDetails,
    ["payments", "elements", indexOfPayment, "premium_paid"]
  );

  return prettyPrintPayment(paidPremium);
}

export const PRETTYPRINT_FALLBACK_STRING = "Nog niet berekend.";
export function prettyPrintPayment(value) {
  if (!isUndefined(value)) {
    return `€ ${value}`;
  }

  return PRETTYPRINT_FALLBACK_STRING;
}
