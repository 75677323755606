import angular from 'angular';
import template from './CustomDossier.html';

const namespace = 'fl/components/dossier/CustomDossier';

angular
  .module(namespace, [])
  .component('customDossier', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<',
      procdefs: '<',
      processes: '<',
      authorizations: '<'
    },
    controller: function () {
      this.FBU_DOSSIER_DEFINITION_KEY = 'fbu';
    }
  })
  .name;

export default namespace;